<template lang="pug">
  .page.entity-list-page
    //- .page__body(v-show="dataLoading")
      .page__loading Загрузка...
    .page__body
      .filter-container
        .filter-item(v-for="(field, key) in filters" :key="key")
          span.filter-item__title {{ field.title }}
          field.filter-item__field(v-model="query[field.name]" :field="field")
        el-button.filter-item(type="primary" icon="el-icon-search" @click="handleFilter" v-if="filters && filters.length") Найти
        //
          el-input(v-model="query.keyword" placeholder="Ключевое слово" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter")
          el-select(v-model="query.role" placeholder="Роль" clearable style="width: 90px" class="filter-item" @change="handleFilter")
            el-option(v-for="item in roles" :key="item" :label="item | uppercaseFirst" :value="item")
        template(v-if="!options.no_create_page")
          router-link.entity-list-page__create-btn(:to="createLink" v-if="!options.modal_create_page")
            el-button(class="filter-item" type="primary" icon="el-icon-plus") Создать
          el-button.entity-list-page__create-btn(class="filter-item" type="primary" icon="el-icon-plus" v-else @click.prevent="openCreateModal()") Создать
      el-table(
        :data="data"
        style="width: 100%"
        v-loading="dataLoading"
      )
        el-table-column(
          v-for="(field, index) in list_fields"
          :key="index"
          :prop="field.name"
          :label="field.title"
          :width="field.width"
          :min-width="field['min-width']"
        )
          template(slot-scope="scope")
            span(v-if="field.type === 'boolean'")
              el-button(icon="el-icon-plus" circle size="small" type="success" v-if="scope.row[field.name]")
              el-button(icon="el-icon-minus" circle size="small" type="danger" v-else)
            .entity-list-page__img-box(
              v-else-if="field.type === 'img'"
            )
              img.entity-list-page__img(
                v-if="scope.row[field.name]"
                :style="{ width: field['img-width'] + 'px', height: field['img-height'] + 'px' }"
                :src="$imaginary((scope.row[field.name] || {}).path, 'crop', { width: field['img-width'], height: field['img-height'] })"
              )
              .entity-list-page__img-placeholder(v-else :style="{ width: field['img-width'] + 'px', height: field['img-height'] + 'px' }")

            el-link.entity-list-page__link(
              v-else-if="field.type === 'link'"
              :href="field.host + field.prefix + ((field.host + field.prefix) ? '/' : '') + scope.row[field.name]"
              target="_blank"
              type="primary"
            ) {{scope.row[field.name].replace(/https?:\/\/[^\/]+\//, '')}}
            span(v-else-if="field.type === 'select'") {{field.options.filter(([name, label]) => name === scope.row[field.name]).map(el => el[1])[0]}}
            span(v-else) {{scope.row[field.name]}}

        el-table-column(
          label="Операции"
          :min-width="104"
          :width="104"
        )
          template(slot-scope="scope")
            .entity-list-page__actions-row
              el-tooltip(effect="dark" content="Просмотреть" placement="top-end" :open-delay="1000" v-if="options.modal_view_page")
                el-button.entity-list-page__action(type="primary" icon="el-icon-view" circle @click.prevent="openViewModal(scope.row.id)")
              el-tooltip(effect="dark" content="Изменить на внутренней странице" placement="top-end" :open-delay="1000" v-if="!options.no_edit_page")
                router-link.entity-list-page__action(:to="editLink(scope.row.id)")
                  el-button(type="primary" icon="el-icon-edit-outline" circle)
              el-tooltip(effect="dark" content="Копировать" placement="top-end" :open-delay="1000" v-if="!options.no_duplicate")
                el-button.entity-list-page__action(type="info" icon="el-icon-copy-document" circle @click.prevent="openDuplicateModal(scope.row.id)")
              el-tooltip(effect="dark" content="Контекстное редактирование" placement="top-end" :open-delay="1000" v-if="!options.no_modal_edit_page")
                el-button.entity-list-page__action(type="primary" icon="el-icon-edit" circle @click.prevent="openEditModal(scope.row.id)")
              el-tooltip(effect="dark" content="Удалить" placement="top-end" :open-delay="1000" v-if="!options.no_delete")
                el-button.entity-list-page__action(type="danger" icon="el-icon-delete" circle @click.prevent="openDeleteModal(scope.row.id)")
            //-
              router-link(:to="editLink(scope.row.id)") Изменить
              el-button( @click.prevent="deleteItem(scope.row.id)"
                type="text"
                size="small"
              ) Удалить
        //-<fields-form :fields="fields" :value="postForm" @input="postForm[$event.key] = $event.value"></fields-form>-->
      el-pagination.entity-list-page__pagination(
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="count"
        :current-page="page"
        @current-change="page = +$event"
      )

    el-dialog.users-list__permissions-dialog(:visible.sync="dialogCreateVisible" :title="'Контекстное создание'")
      .form-container
        el-form.edit-container
          tab-fields(:fields="fields" v-model="createForm")
        div(style="text-align:right;")
          el-button(type="danger" @click="dialogCreateVisible=false") Отменить
          el-button(type="primary" @click="confirmCreate") Сохранить

    el-dialog.users-list__permissions-dialog(:visible.sync="dialogEditVisible" :title="'Контекстное редактирование - ' + current.id")
      .form-container(v-if="current && current.id")
        el-form.edit-container
          tab-fields(:fields="contextFields" v-model="editForm")
        div(style="text-align:right;")
          el-button(type="danger" @click="dialogEditVisible=false") Отменить
          el-button(type="primary" @click="confirmEdit") Сохранить

    el-dialog.entity-list-page__dialog(:visible.sync="dialogViewVisible" width="70%" top="10vh" :title="'Просмотр - ' + current.id")
      .entity-list-page__dialog-inner(v-if="current && current.id")
        el-form.edit-container.entity-list-page__dialog-content
          tab-fields(:fields="contextFields" v-model="editForm")
        div(style="text-align:right;")
          el-button(type="primary" @click="dialogViewVisible = false") Закрыть

    back-to-top
</template>

<script>

  // import FieldsForm from '@/components/fields/FieldsForm.vue';
  import Sticky from '@/components/Sticky'
  import Field from '@/components/fields/Field'
  import TabFields from '@/components/fields/TabFields'
  import BackToTop from '@/components/BackToTop'

  // import {validURL} from '@/utils/validate';
  import { default as request, catchRequest, notifyRequest, notifyErrorRequest } from '@/utils/api'

  export default {
    components: {
      Sticky,
      Field,
      TabFields,
      BackToTop
    },
    data() {
      return {
        request: this.$route.meta.request,
        loading: true,
        dataLoading: true,
        tempRoute: {},

        page: +this.$route.query.page,
        count: 0,

        options: {},
        query: {},
        filters: [],
        contextFields: [],

        fields: [],
        list_fields: [],
        data: [],

        dialogCreateVisible: false,
        dialogCreateLoading: false,
        dialogEditVisible: false,
        dialogEditLoading: false,

        dialogViewVisible: false,

        current: {},
        editForm: {},
        createForm: {}
      }
    },
    computed: {
      lang() {
        return this.$store.getters.language
      },
      createLink() {
        return this.$route.path.replace(/\/list$/, '/create')
      },
      params() {
        const params = {
          ...(this.loading ? { use_default_filters: 1 } : {}),
          ...this.query,
          ...(this.page && this.page !== 1 ? { page: this.page } : {})
        }
        return params
      }
    },
    async created() {
      // Why need to make a copy of this.$route here?
      // Because if you enter this page and quickly switch tag, may be in the execution of the setTagsViewTitle function, this.$route is no longer pointing to the current page
      this.tempRoute = Object.assign({}, this.$route)

      const fetchedData = await this.fetchData()
      console.log(this.loading)
      this.fields = fetchedData.fields
      this.list_fields = fetchedData.list_fields
      this.data = fetchedData.items
      this.count = fetchedData.count
      this.filters = fetchedData.list_filters
      this.contextFields = fetchedData.context_fields
      this.options = fetchedData.options
      this.loading = false
    },
    methods: {
      getField(name) {
        const fields = this.fields.filter(el => el.name === name)
        return fields.length ? fields[0] : null
      },
      editLink(id) {
        return this.$route.path.replace(/\/list$/, '') + '/edit/' + id
      },
      async fetchData() {
        this.dataLoading = true
        const response = await notifyErrorRequest({
          url: `${this.request}`,
          params: this.params,
          method: 'get'
        })
        this.dataLoading = false
        return response
      },
      async handleFilter() {
        const fetchedData = await this.fetchData()
        this.data = fetchedData.items
      },
      openCreateModal() {
        this.dialogCreateVisible = true
        this.current = {}
        this.createForm = {}
      },
      async confirmCreate() {
        this.dialogCreateLoading = true
        const responseData = await notifyRequest({
          url: `${this.request}`,
          method: 'post',
          data: { data: this.createForm },
        }, 'Объект успешно создан')
        this.handleFilter()
        this.dialogCreateLoading = false
        if (!responseData.errors) {
          this.dialogCreateVisible = false
          this.current = {}
        }
      },
      openDuplicateModal(id) {
        let item = this.data.filter(el => {
          return el.id === id
        })
        if (!item || !item.length) {
          return
        }
        item = item[0]
        this.$confirm(`Копировать "${item.title}" (ID: ${item.id})?`, 'Подтвердите копирование', {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.duplicateItem(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Копирование отменено'
          })
        })
      },
      async duplicateItem(id) {
        const response = await catchRequest({
          url: `${this.request}/${id}/duplicate`,
          method: 'post'
        })
        const fetchedData = await this.fetchData()
        this.data = fetchedData.items
        if (response.data.errors) {
          this.$notify({
            title: 'Ошибки при отправке:',
            dangerouslyUseHTMLString: true,
            message: '<ul><li>' + response.data.errors.join('</li><li>') + '</li></ul>',
            type: 'error',
            duration: 7000,
          })
        } else {
          this.$notify({
            title: 'Успешно!',
            dangerouslyUseHTMLString: true,
            message: 'Объект успешно скопирован', // response.data,
            type: 'success',
            duration: 2000,
          })
        }
        return response
      },
      openEditModal(id) {
        let item = this.data.filter(el => {
          return el.id === id
        })
        if (!item || !item.length) {
          return
        }
        item = item[0]
        this.dialogEditVisible = true
        this.current = {}
        this.current.id = item.id
        for (const i in item.context_data) {
          if (this.contextFields.map(el => el.name).includes(i)) {
            this.current[i] = item.context_data[i]
          }
        }
        this.editForm = this.current
      },
      openViewModal(id) {
        let item = this.data.filter(el => {
          return el.id === id
        })
        if (!item || !item.length) {
          return
        }
        item = item[0].context_data
        this.dialogViewVisible = true
        this.current = {}
        for (const i in item) {
          if (['id', ...this.contextFields.map(el => el.name)].includes(i)) {
            this.current[i] = item[i]
          }
        }
        this.editForm = this.current
      },
      async confirmEdit() {
        this.dialogEditLoading = true
        const responseData = await notifyRequest({
          url: `${this.request}/${this.current.id}/context-edit`,
          method: 'put',
          data: { data: this.editForm },
        }, 'Объект успешно сохранен')
        this.handleFilter()
        this.dialogEditLoading = false
        if (!responseData.errors) {
          this.dialogEditVisible = false
          this.current = {}
        }
      },
      openDeleteModal(id) {
        let item = this.data.filter(el => {
          return el.id === id
        })
        if (!item || !item.length) {
          return
        }
        item = item[0]
        if (this.request.includes('__links')) {
          this.$confirm(`Данный документ будет удален из всех разделов, в которых он находится:<br>${item.usages}<br>Удалить навсегда "${item.title}" (ID: ${item.id})?`, 'Подтвердите удаление', {
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отменить',
            dangerouslyUseHTMLString: true
            // type: 'warning'
          }).then(() => {
            this.deleteItem(id)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: 'Удаление отменено'
            })
          })
          return
        }
        this.$confirm(`Удалить навсегда "${item.title}" (ID: ${item.id})?`, 'Подтвердите удаление', {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.deleteItem(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено'
          })
        })
      },
      async deleteItem(id) {
        const response = await catchRequest({
          url: `${this.request}/${id}`,
          method: 'delete'
        })
        this.data = this.data.filter(el => el.id !== id)
        if (response.data.errors) {
          this.$notify({
            title: 'Ошибки при отправке:',
            dangerouslyUseHTMLString: true,
            message: '<ul><li>' + response.data.errors.join('</li><li>') + '</li></ul>',
            type: 'error',
            duration: 7000,
          })
        } else {
          this.$notify({
            title: 'Успешно!',
            dangerouslyUseHTMLString: true,
            message: 'Объект успешно удален', // response.data,
            type: 'success',
            duration: 2000,
          })
        }
        return response
      },
      setTagsViewTitle() {
        const route = Object.assign({}, this.tempRoute)
        this.$store.dispatch('updateVisitedView', route)
      }
    },

    watch: {
      async page() {
        const page = this.$route.query.page || 1
        if (this.page !== 1 && page !== 1 && this.page !== page) {
          this.$router.push({ path: this.$route.path, query: (this.page && this.page == 1 ? { page: this.page } : {}) })
        }
        const fetchedData = await this.fetchData()
        this.data = fetchedData.items
      }
    }
  }
</script>
<style lang="sass">
@import "@/assets/sass/mixin.scss"
.entity-list-page

  .el-table .cell
    word-wrap: break-word
    word-break: normal

  &__dialog
    &-content
      max-height: calc(80vh - 174px)
      overflow-y: auto
      margin-bottom: 20px
    .el-dialog
      padding: 20px 40px
      max-height: 80vh
      &__body
        padding: 20px 0 0
        word-break: normal

  &__pagination
    margin-top: 10px
  &__img-box
    position: relative
  &__img-placeholder
    border: 1px solid #c0ccda
    z-index: 2
    &:before
      display: block
      position: absolute
      top: 0
      width: 100%
      text-align: center
      line-height: 90px
      content: "\e79f"
      font-family: 'element-icons', serif
      speak: none
      font-size: 75px
      color: #C0C4CC
      z-index: 2
    &:after
      display: block
      position: absolute
      width: 120px
      padding-top: 5px
      padding-bottom: 10px
      bottom: 0
      left: calc(50% - 60px)
      line-height: 16px
      text-align: center
      white-space: pre
      content: "Изображение\0aотсутствует"
      font-family: 'element-icons', serif
      speak: none
      font-size: 14px
      color: #C0C4CC
      z-index: 2
  &__actions-row
    display: flex
    flex-flow: row wrap
    justify-content: space-between
    &:after
      content: none
    &:before
      content: none
  &__action, &__action + &__action
    display: block
    margin-top: 4px
    margin-bottom: 4px
    margin-left: 0
    // &:not(:nth-child(2n))
      margin-right: 8px
  &__create-btn
    .el-button
      margin-left: 0
      margin-right: 0
      margin-bottom: 10px
.el-table__body
  max-width: 100%

.filter-item
  height: 36px
  position: relative
  margin-right: 10px
  &__field
    .el-select
      display: block
  &__title
    position: absolute
    bottom: 100%
    left: 5px
    font-size: 12px
    color: grey

</style>
